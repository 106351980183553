.tableContainer {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  letter-spacing: 0.075em;
  border: 8px solid; /* Thickness of the border */
  border-bottom: 0;
  box-sizing:border-box;
  border-image: linear-gradient(90deg, #4b40d0, #60dcf2) 1;
  transition: height 0.3s ease-in-out, margin-top 0.3s ease-in-out;
  min-height: 700px;
}

.tableContent {
  width: 100%;
  height: 80%;
  padding: 2% 2.5% 2% 7.5%;

  box-sizing: border-box;
  display: flex;
  flex-direction: row-reverse;

  transition: height 0.3s ease-in-out, gap 0.3s ease-in-out;
  justify-content: flex-start;
  align-items: stretch;
}

.deviceMakers {
  height: 100%;
  width: 45%;

  align-self: center;
  display: flex;
  flex-direction: column;
}

.contentProviders {
  height: 100%;
  width: 55%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.contentProviders img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/* Gradient for the line */

.tableContent ul {
  height: 70%;
  padding: 1.5% 3%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.tableContent h2 {
  font-size: clamp(1.35em, 2vw, 2em);
  line-height: 75%;
}

.tableContent h4 {
  font-size: clamp(1.25em, 2vw, 2em);
  line-height: 150%;
  letter-spacing: 0.05em;
  text-indent: 0;
  font-weight: 600;

  margin-bottom: 0;
}

.tableContent li {
  font-size: clamp(0.9em, 1.2vw, 1.25em);
  line-height: 200%;
  width: 80%;
  color: #22232a;
}

.tableContainer h3 {
  margin: 0;
  height: 20%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: clamp(1.1em, 1.5vw, 1.5em);
  letter-spacing: 0.025em;
  font-weight: 600;
  text-align: center;
  background: linear-gradient(
    270deg,
    #60dcf2,
    #4a40d0
  ); /* Gradient for the line */
}

@media (max-width: 1665px) {
  .tableContent {
    gap: 10%;
  }

  .deviceMakers {
    width: 40%;
  }
  .contentProviders {
    width: 50%;
  }
}

@media (max-width: 1490px) {
  .tableContainer {
    height: 65vh;
    min-height: 650px;
  }
}

@media (max-width: 1300px) {
  .tableContainer {
    height: 57.5vh;
  }
  .tableContent {
    gap: 5%;
  }
  .contentProviders {
    width: 50%;
  }
  .deviceMakers {
    width: 50%;
  }
  .tableContainer {
    gap: 0;
  }
}

@media (max-width: 1150px) {
  .tableContainer {
    height: 55vh;
    min-height: 500px;
  }
}

@media (max-width: 1050px) {
  .tableContainer {
    height: 50vh;
  }
}
@media (max-width: 800px) {
  .tableContainer {
    height: 90vh;
  }
  .tableContent {
    height: 85%;
    flex-direction: column;
    gap: 5%;

  }
  .deviceMakers {
    width: 100%;
    height: 47.5%;
    justify-content: flex-start;
  }
  .contentProviders {
    width: 100%;
    height: 47.5%;
  }
  .tableContent h2 {
    font-size: 1.25em;
    line-height: 0;
  }
  .tableContent h4 {
    font-size: 0.95em;
    line-height: 125%;
  }
  .tableContent li {
    font-size: 0.8em;
    letter-spacing: 0.01em;
    margin-bottom: 2.5%;
  }
  .tableContainer h3 {
    height: 15%;
    padding: 0 5%;
    font-size: 0.75em;
    font-weight: 500;
  }

}
