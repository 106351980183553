.navbarContainer {
    width: 100%;
    height: 12.5vh;
    display: flex;
    background: #22232a;
    justify-content: space-between;
    padding: 0 5%; /* Reduced padding */
    min-height: 100px;
    align-items: center;
    box-sizing: border-box; /* Ensure padding doesn't affect total width */
}

.navbarLogo {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5%;
    justify-content: center ;

}
.navbarLogo img {
    height:30%;
    cursor: pointer;
}
.navbarContainer h2 {
    font-size: .95em;
    color: white;
    font-weight: 400;
}
.navbarLogo h2 {
    font-size: .70em;
    line-height: 0;
}
.legal {
    display: flex;
    margin-top: 5%;
    align-items: center; /* Ensures proper vertical alignment */
    gap: 2%; /* Optional: Adjust to control spacing */
  }
  
  .legal a{
    text-decoration: none;
    position: relative;
    color:white;
    font-size: .7em;
    cursor: pointer;
  }
  
  .legal a:not(:last-child)::after {
    content: "|";
    margin-left: .45em; /* Adjust for space between text and bar */
    color: #ccc; /* Change to your desired bar color */
  }
  


@media(max-width: 600px) {
    .navbarLogo img {
        height:20%;
    }
    .navbarContainer {
        min-height: 80px;
    }
    .navbarContainer h2 {
        font-size: .45em;
        color: white;
        font-weight: 400;
    }
    .link {
        font-size: .45em;
    }
    .navbarContainer a {
        font-size: .45em;
    }
    
}