.legalsContainer {
  width: 50%;
  margin-left: 25%;
  white-space: pre-line;

}

.legalsContainer h1 {
  margin: 0;
  font-size: clamp(2.5em, 5vw, 4.25em);
  font-family: "Inter", sans-serif;
  font-weight: 300;
  margin: 5% 0;
  display: flex;
  align-items: flex-end;
}

.legalsContent {
  height: max-content;
  margin-top: 5%;
  width: 100%;
}

.section {
  margin-bottom: 5em;
}

.sectionTitle {
  font-size: clamp(1.5em, 4vw, 2em);
  font-weight: 500;
  margin-bottom: 1em;
}

.subtitleContainer {
  margin-bottom: 15px;
}

.subtitle {
  font-size: clamp(1em, 3vw, 1.75em);

  font-weight: 400;
}

.subtitleExpl {
  font-weight: 300;
  font-size: clamp(0.85em, 2.5vw, 1em);
  line-height: 170%;
  white-space: pre-line;
}

.subSubtitle {
  font-size: clamp(1em, 2.5vw, 1.25em);
  font-weight: 450;
}

.subSubtitleExpl {
  font-size: clamp(0.9em, 2.5vw, 1em);
  line-height: 170%;
  font-weight: 300;
}

.tableContainer {
  margin-top: 10px;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  margin: 2% 0;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
  font-size: clamp(0.9em, 2.5vw, 1.15em);
  font-weight: 500;
}

.table td {
  font-size: clamp(0.85em, 2vw, 1em);
  font-weight: 300;
}

.legalsContainer h5 {
  font-size: clamp(1em, 2.5vw, 1.25em);
  font-weight: 450;
}
.legalsContainer p {
  font-size: clamp(0.9em, 2.5vw, 1em);
  line-height: 170%;
  font-weight: 300;
}
.imprintContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 25%;
  box-sizing: border-box;
  min-height: 75vh;
}
.imprintContent h4 {
  font-size: clamp(0.9em, 2.5vw, 1.15em);
  line-height: 170%;
  font-weight: 300;
}
.imprintContainer h1 {
  margin: 0;
  font-size: clamp(2.5em, 5vw, 4.25em);
  font-family: "Inter", sans-serif;
  font-weight: 300;
  margin: 5% 0;
  display: flex;
  align-items: flex-end;
}

@media (max-width: 600px) {
  .imprintContainer {
    width: 80%;
    margin-left: 10%;
  }
  .legalsContainer {
    width: 80%;
    margin-left: 10%;
  }

  .table th,
  .table td {
    border: 1px solid #ddd;
    padding: 4px;
    text-align: left;
  }

  .table th {
    background-color: #f2f2f2;
    font-size: clamp(0.5em, 2.5vw, 0.75em);
    font-weight: 400;
  }

  .table td {
    font-size: clamp(0.5em, 2vw, 0.75em);
    font-weight: 400;
  }
}
