.customButtonContainer {
  width: 300px;
  height: 50px;
  background: linear-gradient(90deg, #4b40d0 0%, #60dcf2 100%);
  border-radius: 1em;
  font-size: 1.25em;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center; /* Center content in button */
  border: none;
  cursor: pointer;
  align-self: center;
}
.customButtonContainer a {
  text-decoration: none;
  color: white;
}
@media (max-width: 800px) {
  .customButtonContainer {
    width: 150px;
    height: 40px;
    border-radius: 1.5em;
    font-size: .7em;
  }
}
