.introductionContainer {
  width: 100%;
  height: 85vh;
  display: flex;
  min-height: 900px;
  flex-direction: column;
}
.introduction {
  height: 64.5%;
  display: flex;
  padding: 0 7.5%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.slogan {
  width: 70em;
}
.logo {
  width: 40em;
}

.introduction h1 {
  font-size: clamp(2.5em, 4.5vw, 4.5em);
  transition: font-size 0.3s ease-in-out;
  line-height: 160%;
  font-weight: 650;
}
.introduction img {
  width: 100%;
}
.introductionActions {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 35.5%;
  gap: 10%;
  padding: 0 7.5%;
}
.introductionActions h2 {
  font-size: clamp(1.15em, 2vw, 2em);
  transition: font-size 0.3s ease-in-out;
  font-weight: 450;
}

@media (max-width: 1490px) {
  .introductionContainer {
    height: 75vh;
  min-height: 550px;

  }
}
@media (max-width: 1150px) {
  .introductionContainer {
    height: 65vh;
  }
}
@media (max-width: 800px) {
  .introductionContainer {
    height: 85vh;
    min-height: 550px;


  }
  .introduction {
    flex-direction: column;
    padding: 0 2.5%;
    align-items: center;
    height: 57.5%;

    gap: 5%;
  }
  .introduction h1 {
    font-size: 1em;
    line-height: 190%;
  }
  .slogan {
    width: 100%;
    height: 60%;
    font-size: 1.75em;
    text-align: center;
  }
  .logo {
    width: 85%;
  }
  .introduction img {
    width: 100%;
    justify-self: center;
  }
  .introductionActions {
    height: 42.5%;
    gap: 5%;
  }
  .introductionActions h2 {
    font-size: 0.85em;
  }
}
