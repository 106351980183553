
@font-face {
  font-family: "Inter";
  src: url("../Assets/fonts/Inter/Inter-VariableFont_opsz,wght.ttf") format("truetype");
  font-weight: 100 900;
  font-style: normal;
}

html, body {
  padding:0;
  background: white;
  margin:0;
  box-sizing: border-box;
  font-family: "Inter",sans-serif;
}
.App { 
  display: flex;
  flex-direction: column;
}
* {
  box-sizing: border-box;
  padding: 0;
}

@media(max-width: 800px) {
  ::-webkit-scrollbar {
    display: none;
  }
  
}