.finishContainer {
  height: 85vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 5vh;
  margin-top: 5vh;
  transition: height 0.3s ease-in-out, margin-top 0.3s ease-in-out;
  align-items: center;
  min-height: 750px;
}

.cinemoDirections {
  width: 80%;
  height: 64.5%;
  padding: 0 7.5%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.cinemoDirections img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.endContent {

  height: 35.5%;
  gap: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.endContent h3 {
  text-align: center;
  font-size: clamp(1.1em, 1.5vw, 1.5em);
  font-weight: 400;
}

@media (max-width: 1490px) {
  .finishContainer {
    height: 77.5vh;

    min-height: 500px;
  }
}
@media (max-width: 1100px) {
  .finishContainer {
    height: 60vh;
  }
}
@media (max-width: 800px) {
  .finishContainer {
    height: 50vh;
    min-height: 300px;

  }
  .cinemoDirections {
    width:100%;
    height: 50%;
  }
  .cinemoDirections img {
    width: 100%;
    height:100%;
    
  }

  .endContent {
    height: 55%;
    width: 80%;
  }
  .endContent h3 {
    font-size: 0.75em;
    line-height: 150%;
  }
}
